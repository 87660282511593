import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import './styles/sign.scss';
import axios from 'axios';

import Loading from '../customComponents/Loading';

import { serverAddress as proxy, serverAddress } from "../custom-modules/customModules";

import hiddenIcon from '../Assets/general/hidden.png';
import showIcon from '../Assets/general/view.png';



import loadingGif from '../Assets/general/loader.gif';



function SignUp() 
{

    const [emailField, setEmailField] = useState("");
    const [usernameField, setUsernameField] = useState("");
    const [passwordField, setPasswordField] = useState("");
    const [repasswordField, setRepasswordField] = useState("");

    const [viewPassword, setViewPassword] = useState(false);
    const [viewRepassword, setViewRepassword] = useState(false);

    const [agreementCheck, setAgreementCheck] = useState(false);

    const [isSigning, setIsSigning] = useState(false);

    const [isVerifying, setIsVerifying] = useState(false);
    const [isCreatingAccount, setIsCreatingAccount] = useState(false);
    const [verificationError, setVerificationError] = useState("");

    const [resendTimeout, setResendTimeout] = useState(0);

    const [isResendable, setIsResendable] = useState(false);

    // const [newUser, setNewUser] = useState({});


    const [errorText, setErrorText] = useState({
        generalError: "",
        emailError: "",
        usernameError: "",
        passwordError: "",
        repasswordError: "",
        agreementError: ""
    });

    // verification
    const [verificationField, setVerificationField] = useState("");

    const [isLoaded, setIsLoaded] = useState(false);


    const redirect = useNavigate();


    let signedUp = false;

    if (isResendable === false)
    {
        setTimeout(() => 
        {
            setIsResendable(true);
            // console.log("set!");
        }, 10000);
    }

    if (!isLoaded)
    {
        if (localStorage.getItem("queue-username") == "" || localStorage.getItem("queue-username") == null) setIsLoaded(true);
        else 
        {
            axios.post(`${proxy}/queued`, { username: localStorage.getItem("queue-username") })
                .then((res) => 
                {
                    if (res.data.length > 0) 
                    {
                        let data = res.data[0];

                        setEmailField(data.email);
                        setUsernameField(data.username);
                        setPasswordField(data.password);

                        setIsVerifying(true);
                    }
                    else localStorage.setItem("queue-username", "");

                    setIsLoaded(true);
                })
                .catch((err) => console.error(err));
        }
    }

    function setupEmailField(value)
    {
      let bannedCharacters = ["#", "/", "*", "$", "^", "&", "(", ")", "+", "-", "!", "~", "`", " "];
  
      for (let i = 0; i < bannedCharacters.length; i += 1)
      {
        if (value.charAt(value.length - 1) == bannedCharacters[i])
        {
          value = value.slice(0, value.length - 1);
          break;
        }
      }
  
      let numberOfAtsings = (value.match(new RegExp("@", "g")) || []).length;

      if (numberOfAtsings > 1) value = value.slice(0, value.length - 1);
  
      setEmailField(value);
    }

    function setupUsernameField(value)
    {
      let bannedCharacters = ["#", "/", "*", "$", "^", "&", "(", ")", "+", "-", "!", "~", "`", " ", "@"];
  
      for (let i = 0; i < bannedCharacters.length; i += 1)
      {
        if (value.charAt(value.length - 1) == bannedCharacters[i])
        {
          value = value.slice(0, value.length - 1);
          break;
        }
      }
  
  
      setUsernameField(value);
    }

    function setupVerificationField(value)
    {
        value = value.toString();

        let bannedCharacters = ["#", "/", "*", "$", "^", "&", "(", ")", "+", "-", "!", "~", "`", " ", "@", "'"];
  
      for (let i = 0; i < bannedCharacters.length; i += 1)
      {
        if (value.charAt(value.length - 1) == bannedCharacters[i])
        {
          value = value.slice(0, value.length - 1);
          break;
        }
      }
  
  
      setVerificationField(value.toUpperCase());
    }

    function setView(value)
    {
        if (value == false)
        {
            return hiddenIcon;
        }
        else
        {
            return showIcon;
        }
    }

    function goBack()
    {
        setIsVerifying(false);
    }


    function resendEmail(username, email, password)
    {

        setIsResendable(false);
        setVerificationError("");
        axios.post(`${proxy}/resend-verification`, { username, email, password })
        .then((res) => 
            {
                if (res.data === false) 
                {
                    console.log("Could not resend verification code!");
                    setVerificationError("Something Went Wrong!");
                }
                else if (res.data == "err-mems-0013")
                {
                    setVerificationError("Too many requests were sent from this origin! Please contact staff.")
                }


            })
            .catch((err) => console.error(err));
    }

    function signUp()
    {
        // Error checking
        setIsSigning(true);
            
            
            
            
            
        setErrorText({
            emailError: "",
            usernameError: "",
            passwordError: "",
            repasswordError: "",
            agreementError: ""
        });

        let hasErrors = false;

        let tempErrorText = {
            generalError: "",
            emailError: "",
            usernameError: "",
            passwordError: "",
            repasswordError: "",
            agreementError: ""
        };

        if (emailField.includes("@") == false)
        {
            hasErrors = true;
            tempErrorText.emailError = "Email did not contain the necessary characters!";
        }

        if (emailField.includes(".") == false)
        {
            hasErrors = true;
            tempErrorText.emailError = "Email did not contain the necessary characters!";
        }
        
        if (passwordField.length < 8)
        {
            hasErrors = true;
            tempErrorText.passwordError = "Password should at least be 8 characters!";
        }
        
        if (repasswordField != passwordField)
        {
            hasErrors = true;
            tempErrorText.repasswordError = "Passwords do not match!";
        }
        
        
        
        if (emailField.length == 0)
        {
            hasErrors = true;
            tempErrorText.emailError = "Please fill in the blanks!";
        }
        if (usernameField.length == 0)
        {
            hasErrors = true;
            tempErrorText.usernameError = "Please fill in the blanks!";
        }
        if (passwordField.length == 0)
        {
            hasErrors = true;
            tempErrorText.passwordError = "Please fill in the blanks!";
        }
        if (repasswordField.length == 0)
        {
            hasErrors = true;
            tempErrorText.repasswordError = "Please fill in the blanks!";
        }
        
        if (agreementCheck != true)
        {
            hasErrors = true;
            tempErrorText.agreementError = "You have to agree to the Terms and Conditions!";
        }
            
            
            
        setErrorText(tempErrorText);
        
        if (hasErrors == true)
        {
            console.error("Error!: Could not log in! There was an error while singing in.");
            setIsSigning(false);
            return;
        }

        // let newUserTemp = {
        //     id: accountId,
        //     username: usernameField,
        //     email: emailField,
        //     password: passwordField,
        //     displayName: "",
        //     phoneNumber: "",
        //     profileImage: "",
        //     profileDescription: "",
        //     dateOfCreation: `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDay()}`,
        //     posts: [],
        //     coins: 10,
        //     followedAccounts: JSON.stringify([]),
        //     selectedCategories: JSON.stringify([]),
        //     likedPosts: JSON.stringify([]),
        //     dislikedPosts: JSON.stringify([]),
        //     boostedPosts: JSON.stringify([]),
        //     roles: JSON.stringify([])
        // }

        


        axios.post(`${proxy}/getUsernameEmailStatus`, { username: usernameField, email: emailField })
            .then((res) => 
            {
                let tempErrorText = {
                    generalError: "",
                    emailError: "",
                    usernameError: "",
                    passwordError: "",
                    repasswordError: "",
                    agreementError: ""
                };
                let data = res.data;


                if (data != "f")
                {
                    setIsSigning(false);
                }

                switch (data)
                {
                    case "ue":
                        {
                            tempErrorText = { ...tempErrorText, usernameError: "Username is taken!", emailError: "Email address is taken!" };
                        }  
                        break;
                    
                    case "e":
                        {
                            tempErrorText = { ...tempErrorText, emailError: "Email address is taken!" };
                        }
                        break;

                    case "u":
                        {
                            tempErrorText = { ...tempErrorText, usernameError: "Username is taken!" };
                        }
                        break;

                    case "f":
                        {
                            axios.post(`${serverAddress}/verifyUser`, { username: usernameField, email: emailField, password: passwordField })
                                .then((res) => 
                                {
                                    // console.log(res.data)
                                    localStorage.setItem("queue-username", usernameField);
                                    setIsVerifying(true);
                                    setIsResendable(false);
                                    // setNewUser(newUserTemp); 
                                    setIsSigning(false);
                                    if (res.data == "err-mems-0013")
                                    {
                                        setVerificationError("Too many requests were sent from this origin! Please contact staff.")
                                    }
                                    
                                })
                                .catch((err) => 
                                {
                                    setErrorText({ ...errorText, generalError: "Something went wrong!" });
                                    console.error(err);
                                    setIsSigning(false);
                                });
                        }
                        break;
                }

                setErrorText(tempErrorText);
            })
            .catch((err) => console.log(err));

        
    }

    function createAccount()
    {
        let currentDate = new Date();

        let accountId = uuid();

        let newUser = {
            id: accountId,
            username: usernameField,
            email: emailField,
            password: passwordField,
            displayName: "",
            phoneNumber: "",
            profileImage: "",
            profileDescription: "",
            dateOfCreation: `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDay()}`,
            posts: [],
            coins: 10,
            followedAccounts: JSON.stringify([]),
            selectedCategories: JSON.stringify([]),
            likedPosts: JSON.stringify([]),
            dislikedPosts: JSON.stringify([]),
            boostedPosts: JSON.stringify([]),
            roles: JSON.stringify([])
        };

        let successful = false;

        axios.post(`${proxy}/create`, {verification: verificationField, ...newUser})
            .then((res) => 
            {
                if (res.data == "username is taken" || res.data == "email is taken")
                {
                    setVerificationError("Something Went Wrong!");
                    return;
                }
                signedUp = "true-signedup";
                successful = true;
                setIsCreatingAccount(false);
                if (res.data === true)
                {
                    localStorage.setItem("user", accountId);
                    localStorage.setItem("queue-username", "");
                    redirect("/");
                }
                else setVerificationError("The entered verification code is not correct!");
                // console.log(res);
            })
            .catch(() => 
            {
                console.error("Could not send data to the server!");
                setVerificationError("Something Went Wrong!");
            });

            

            
        
        if (successful == true) 
        {
            
            console.log(accountId);
        }
    }


    if (!isLoaded) return <Loading />;

    if (isVerifying)
        return (
            <>
                <br />
                <div className="containerCls">
                    <div className="signInCls1">
                        <div className="sign-container panel">
                            <div className="sign-details">
                            <div style={{ textAlign: "left" }}><button className="btn" style={{ padding: "10px 20px 10px 20px" }} onClick={goBack}>Back</button></div> <br /> <br />
                                <h3>Verification Code Sent!</h3> <br />
                                <span>a verification code was sent to <strong>{emailField}</strong>.<br />Please enter the code below to continue. <br /><br />If the email didn't apear in your Inbox, check your <strong>Spams</strong> or <strong>Junk</strong> folder.</span> <br /> <br />
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <div className="field"><input className="input-field" style={{ textAlign: "center", fontSize: "25px" }} value={verificationField} onInput={(e) => setupVerificationField(e.target.value)} maxLength={6} /></div> 
                                </div><br />
                                <span className="sign-title-error">{verificationError}</span> <br />
                                    {
                                        (isResendable) ?
                                            <button className="btn-blue" style={{ padding: "15px 10px 15px 10px" }} onClick={() => resendEmail(usernameField, emailField, passwordField)}>Resend Code</button>
                                            :
                                            <button className="btn-deactivated" style={{ padding: "15px 10px 15px 10px" }}>Resend Code</button>
                                    } <br /> <br /> 
                                    <span><strong>Warning!:</strong> You can only re-send <strong>5 times!</strong> If you are not getting any verification codes even after resending, contact admins.</span><br /> <br /> <br />
                                    {(isCreatingAccount) ?
                                        <div style={{ justifyContent: "center", alignItems: "center" }}><img src={loadingGif} style={{ width: "50px" }} /></div>
                                        :
                                        <button className="btn" style={{ padding: "20px 15px 20px 15px", fontWeight: "bold" }} onClick={createAccount}>Create Account</button>}
                            </div>
                        </div>
                    </div>
                </div>
            </>    
        ); 

  return (
    <>
        <br />
        <div className="containerCls">
            <div className="signInCls1">
                <div className="sign-container panel">
                    <div className="sign-details">
                        <h3>Create a Memedify account</h3>
                        <span className="sign-title-error">{errorText.generalError}</span> <br />
                        <section>
                            <label className="sign-title-label">Email</label> <br />

                            <div className="field">
                                <input value={emailField} onInput={(e) => setupEmailField(e.target.value)} className="input-field" />
                            </div>
                            <span className="sign-title-error">{errorText.emailError}</span> <br /> 


                            <label className="sign-title-label">Username</label> <br />

                            <div className="field">
                                <input value={usernameField} onInput={(e) => setupUsernameField(e.target.value)} className="input-field" />
                            </div>
                            <span className="sign-title-error">{errorText.usernameError}</span> <br />


                            <label className="sign-title-label">Password</label> <br />

                            <div className="field">
                                <input type={viewPassword == false ? "password" : "text"} value={passwordField} onInput={(e) => setPasswordField(e.target.value)} className="input-field" />
                                <button onClick={() => setViewPassword(!viewPassword)} style={{ cursor: "pointer", background: "none", border: "none" }}>
                                    <img src={setView(viewPassword)} style={{ width: "25px" }} />
                                </button>
                            </div>
                            <span className="sign-title-error">{errorText.passwordError}</span> <br />


                            <label className="sign-title-label">Confirm Password</label> <br />

                            <div className="field">
                                <input type={viewRepassword == false ? "password" : "text"} value={repasswordField} onInput={(e) => setRepasswordField(e.target.value)} className="input-field" />
                                <button onClick={() => setViewRepassword(!viewRepassword)} style={{ cursor: "pointer", background: "none", border: "none" }}>
                                    <img src={setView(viewRepassword)} style={{ width: "25px" }} />
                                </button>
                            </div>
                            <span className="sign-title-error">{errorText.repasswordError}</span> <br /> <br />


                            <section>
                                <input type="checkbox" checked={agreementCheck} onInput={() => setAgreementCheck(!agreementCheck)} />
                                <label style={{ color: "gray", fontWeight: "bold" }}>By Checking this checkbox, you agree to Memedify Terms of Service.</label> <br />
                                <span className="sign-title-error">{errorText.agreementError}</span>
                            </section> <br />

                            {
                                (isSigning) ?
                                    <div style={{ justifyContent: "center", alignItems: "center" }}><img src={loadingGif} style={{ width: "50px" }} /></div>
                                    :
                                    <button className="sign-btn btn" onClick={signUp}>Sign Up</button>
                            } <br /> <br />
                            <div style={{ textAlign: "left" }}><label>Already have a Memedify account? <Link to="/signin"><span>Sign In</span></Link>!</label></div>
                        </section>
                    </div>
                </div>
            </div>
            
        </div>
        <br /> <br />
    </>
  );
}

export default SignUp;