import React, { useEffect, useState } from 'react';
import { Outlet, Link, useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from 'react-redux';
import { actionCreators } from '../redux/actionCreators';
import { bindActionCreators } from 'redux';

import './styles/globalStyles.scss';
import './styles/layout.scss';

import axios from 'axios';
import { serverAddress as proxy, parseSQLArrayProperties, domainName, splitByCharacter, compareDateWithToday, convertDate, filterText } from '../custom-modules/customModules';

import logo from '../Assets/logo.png';
import loaderGif from '../Assets/general/loader.gif';

import coinsImage from '../Assets/general/coins.png';
import UnclickedAddPostImg from '../Assets/general/unclicked-addPost.png';
import ClickedAddPostImg from '../Assets/general/clicked-addPost.png';
import searchIcon from '../Assets/magnifying-glass.png';

import unclickedHomeIcon from '../Assets/general/unclicked-home.png';
import clickedHomeIcon from '../Assets/general/clicked-home.png';
import unclickedCategorytableIcon from '../Assets/general/unclicked-categorytable.png';
import clickedCategorytableIcon from '../Assets/general/clicked-categorytable.png';
import unclickedAccountIcon from '../Assets/general/unclicked-account.png';
import accountIcon from '../Assets/general/clicked-account.png';
import unclickedFollow from '../Assets/unclicked-follow.png';
import clickedFollow from '../Assets/clicked-follow.png';
import dashboardIcon from '../Assets/dashboard.png';

import ordiousIcon from '../Assets/ordious-icon.png'; //red-warning.png
import warningIcon from '../Assets/general/warning.png';
import redWarningIcon from '../Assets/general/red-warning.png';
import Loading from '../customComponents/Loading';

import blockIcon from '../Assets/general/block.png';
import LoadingSmall from '../customComponents/LoadingSmall';

import notifIcon from '../Assets/notifs.png';

function Layout() 
{
    // localStorage.setItem("user", "")
    const redirect = useNavigate();

    // return (<Outlet />);

    const pathname = window.location.pathname;
    const fullPathname = window.location.href;

    console.log(fullPathname.slice(0, 7));

    useEffect(() => 
    {
        if (fullPathname.slice(0, 7) == "http://")
        {
            window.location.replace(`https://${domainName}`);
        }
    }, []);

    // useEffect(() => 
    // {
    //     console.log(window.innerWidth, "a");
    // }, [window.innerWidth]);
    
    useEffect(() => 
    {
        if (window.innerWidth <= 670) localStorage.setItem("windowMode", "phone");
        else localStorage.setItem("windowMode", "desktop");  

        localStorage.setItem("acceptedSize", 670);
    }, []);

    window.addEventListener("resize", () => 
    {
        if (window.innerWidth <= 670) localStorage.setItem("windowMode", "phone");
        else localStorage.setItem("windowMode", "desktop");
    });

    // console.log(pathname);

    const reduxState =  useSelector((state) => state);

    const currentPage = reduxState.currentPage;
    const dispatch = useDispatch();
    const [loaded, setLoaded] = useState(false);
    const [isConnected, setIsConnected] = useState(undefined);

    if (isConnected === undefined)
    {
        axios.post(`${proxy}/isConnected`)
            .then((res) => 
            {
                console.log(res.data);
                setIsConnected(true);
            })
            .catch((err) => 
            {
                console.error("could not establish a connection to the database!");
                setIsConnected(false);
            });
    }


    const { incrementCoins, decrementCoins, setCurrentPage, setSearch } = bindActionCreators(actionCreators, dispatch);

    const [searchInput, setSearchInput] = useState("");
    const [coins, setCoins] = useState(undefined);
    const [accountError, setAccountError] = useState(false);
    
    // ban related
    const [banned, setBanned] = useState(false);
    const [bannedUserInfo, setBannedUserInfo] = useState({});
    const [banDesc, setBanDesc] = useState("");
    const [banDate, setBanDate] = useState("");
    const [banPermanent, setBanPermanent] = useState(false);
    const [unbanAllowed, setUnbanAllowed] = useState(false);
    const [unbanAgree, setUnbanAgreee] = useState(false);
    const [unbanError, setUnbanError] = useState("");
    const [unbanLoading, setUnbanLoading] = useState(false);
    const [doesApeal, setDoesApeal] = useState(false);
    const [apealText, setApealText] = useState("");
    const [apealError, setApealError] = useState("");
    const [isApealingBan, setIsApealingBan] = useState(false);
    const [appealSituation, setAppealSituation] = useState("");

    // const [viewWidth, setViewWidth] = useState(window.innerWidth);

    if (loaded == false)
    {

        if (localStorage.getItem("user") != "" && localStorage.getItem("user") != null)
        {
            axios.post(`${proxy}/getUserById`, { id: localStorage.getItem("user") })
                .then((res) => 
                {
                    console.log(res.data[0]);
                    let user = parseSQLArrayProperties(res.data[0]);
                    let coins = Number(user.coins);
    
                    setCoins(coins);
                    axios.post(`${proxy}/banCheck`, { id: localStorage.getItem("user") })
                        .then((res) => 
                        {
                            let isBanned = res.data;
                            if (isBanned != undefined) setBanned(isBanned);
                            else setBanned(false);

                            if (isBanned == true)
                            {
                                axios.post(`${proxy}/getBannedByUserId`, { id: localStorage.getItem("user") })
                                    .then((res) => 
                                    {
                                        if (res.data.length == 0) 
                                        {
                                            setAccountError(true);
                                            setLoaded(true);
                                        }
                                        else
                                        {
                                            let userData = res.data[0];
                                            setBannedUserInfo(userData);
                                            setBanDesc(userData.reason);
                                            let date;
                                            let clock;
                                            if (userData.date != "p") 
                                            {
                                                setBanDate(convertDate(splitByCharacter(userData.date, "-")[0]));
                                                [date, clock] = splitByCharacter(userData.date, "-");
                                                if (compareDateWithToday(date, clock) == "<") setUnbanAllowed(true);
                                            }
                                            else setBanPermanent(true);

                                            if (userData.status == "allowed" || userData.status == "unbanned") setUnbanAllowed(true);
                                            console.log(userData.status);

                                            axios.post(`${proxy}/appealCheck`, { ban_id: userData.ban_id })
                                                .then((res) => 
                                                {
                                                    let data = res.data;
                                                    data = data.toString();
                                                    console.log(data);
                                                    setAppealSituation(data);

                                                    setLoaded(true);
                                                })
                                                .catch((err) => console.error(err));
                                            
                                        }
                                    })
                                    .catch((err) => console.error(err));
                            }
                            else setLoaded(true);
                        })
                        .catch((err) => console.error(err));
                })
                .catch((err) => console.error(err));
        }
        else setLoaded(true);
    }

    function setupApealText(value)
    {
        value = value.toString();
        let numberOfLineBreaks = (value.match(new RegExp("\n", "g")) || []).length;

        if (value.includes("'") || value.includes('"') || value.includes("`")) return;
        if (numberOfLineBreaks > 5) return;

        setApealText(value);
    }

    function submitBanApeal()
    {
        setIsApealingBan(true);
        setApealError("");
        let isValid = true;

        if (apealText.length == 0)
        {
            setApealError("Please provide a description for your request!");
            isValid = false;
        }

        if (apealText.length > 500)
        {
            setApealError("Way too many characters!");
            isValid = false;
        }


        if (!isValid)
        {
            setIsApealingBan(false);
            return;
        }


        axios.post(`${proxy}/submitAppeal`, { ban_id: bannedUserInfo.ban_id, user_id: bannedUserInfo.user_id, mod_id: bannedUserInfo.mod_id, appeal_desc: apealText })
            .then((res) => 
            {
                console.log(res.data);
                setDoesApeal(false);
                setIsApealingBan(false);
                setLoaded(false);
            })
            .catch((err) => console.error(err));
    }

    function setImageSource(type)
    {
        if (type == currentPage)
        {
            if (type == "home")
            {
                return clickedHomeIcon;
            }
            else if (type == "categorytable")
            {
                return clickedCategorytableIcon;
            }
            else if (type == "follows")
            {
                return clickedFollow;
            }
            else if (type == "account")
            {
                return accountIcon;
            }
        }
        else if (type != currentPage)
        {
            if (type == "home")
            {
                return unclickedHomeIcon;
            }
            else if (type == "categorytable")
            {
                return unclickedCategorytableIcon;
            }
            else if (type == "follows")
            {
                return unclickedFollow;
            }
            else if (type == "account")
            {
                return unclickedAccountIcon;
            }
        }

        console.log(`Error!: Cannot specify the status buttons image sources!`);
    }

    function logout()
    {
        localStorage.setItem("user", "");
        setLoaded(false);
        window.location.reload();
    }

    function unban()
    {
        let valid = true;

        setUnbanError("");
        setUnbanLoading(true);


        if (!unbanAgree)
        {
            valid = false;
            setUnbanError("You have to agree to the Terms of Service in order to be unbanned!");
        }

        if (!valid) 
        {
            setUnbanLoading(false);
            return;
        }

        axios.post(`${proxy}/modifyUser`, { id: localStorage.getItem("user"), action: "modifyStatus", property: "accepted" })
            .then((res) => 
            {
                axios.post(`${proxy}/unbanUserById`, { id: localStorage.getItem("user") })
                    .then((res) => 
                    {
                        setUnbanLoading(false);
                        window.location.reload();
                    })
                    .catch((err) => console.error(err));
            })
            .catch((err) => console.error(err));
    }

    // 919

    if (loaded == false)
    {
        return (<Loading />);
    }

    if (isConnected === false)
    {
        return (
            <div style={{ padding: "15px", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                <img src={warningIcon} style={{ width: "100px" }} />
                <h1>Cannot Connect to the Database!</h1>
                <span>Memedify cannot establish a connection to the Database.<br /> This is a problem on our end. Please refresh the page and if the problem presists, please be patient until the problem gets solved by the ORDIOUS Team.</span> <br /> <br />
                <span>To get updated on the problem, join <a href="https://discord.gg/pvZXeqHdHS">ORDIOUS' Discord Server</a>.</span> <br /> <br />

            </div>
        );
    }

    if (accountError == true)
    {
        return (<div className="content-page">
            <img src={redWarningIcon} style={{ maxWidth: "100px" }} />
            <h3>An Error occurred!</h3>
            <br /><br />
            <span>A technical error occurred to your account!<br /> 
            <span>Please contact ORDIOUS' staff to fix the problem.</span> <br /> <br /> <br />
            <button className="btn banpage-btn" style={{ padding: "10px 20px 10px 20px" }} onClick={logout}>Log Out</button>
            </span>
        </div>);
    }

    if (banned == true)
    {
        return (<div className="content-page2">
            <br /> <br /><br />
            <img src={blockIcon} style={{ maxWidth: "100px" }} />
            <h3>You are {(banPermanent) ? "Permanently" : "Temporarily"} Banned!</h3> <br />
            <span className="dashboard-section-title">REASON</span>
            <span style={{ whiteSpace: "pre-line" }}>{banDesc}</span> <br /> 
            {   (!unbanAllowed) ?
                    <>
                        {   (!banPermanent) ?
                                <><span className="dashboard-section-title">BANNED UNTIL</span> 
                                <span>{banDate}</span> <br /></>
                                :
                                <></>
                        }

                        <span className="dashboard-section-title">APPEAL BAN</span>
                        {
                            (appealSituation == "t-f") ?
                                <span>You have already submitted an appeal.<br />Your request is going to be checked my a moderator.</span>
                                :
                                (appealSituation == "t-t") ?
                                    <span>You have already submitted an appeal.<br />Your request has been checked my a moderator.</span>
                                    :
                                    (doesApeal) ?
                                        <div className="panel" style={{ maxWidth: "600px" }}>
                                            <br />
                                            <span>If you are sure we have made a mistake, you can submit an appeal so a different moderator will check your case.<br /><strong>You can only appeal once!</strong><br /> The moderator will check the case again and your ban will be decided upon them. This might result in the removal of your ban, getting a less severe ban, not changing your situation, or getting a more severe ban. <br />Write about your request below:</span> <br /> <br />
                                            <div className="field" >
                                                <textarea className="textarea-field" placeholder="write about your request. why is your ban unfair?..." value={apealText} onInput={(e) => setupApealText(e.target.value)} maxLength={500} />
                                            </div>
                                            <div className="newpost-properties">
                                                <span className="newpost-error-label">{apealError}</span>
                                                <div><label>{apealText.toString().length} / 500</label></div>
                                            </div> <br />
                                            {
                                                (isApealingBan) ?
                                                    <img src={loaderGif} style={{ width: "60px" }} />
                                                    :
                                                    <>
                                                        <button className="del-btn" style={{ padding: "20px 30px 20px 30px", margin: "5px" }} onClick={() => setDoesApeal(false)}>Cancel</button>
                                                        <button className="btn" style={{ padding: "20px 30px 20px 30px" }} onClick={submitBanApeal}><strong>Submit</strong></button>
                                                    </>
                                            }
                                        </div>
                                        :
                                        <div>
                                            <span>If you think there is something wrong with your situation, you can submit an appeal.</span> <br /> <br />
                                            <button className="btn" style={{ padding: "10px 20px 10px 20px" }} onClick={() => setDoesApeal(true)}>Appeal</button>
                                        </div>
                        }
                        {/* <span>If you have any problems with your situation, email <a href="mailto:apeal@memedify.com">apeal@memedify.com</a>.</span> */}
                    </>
                    :
                    <div className="panel">
                        <span>You are now able to un-ban yourself by re-agreeing the Terms of Service.</span> <br />
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <input type="checkbox" value={unbanAgree} onChange={() => setUnbanAgreee(!unbanAgree)} /> 
                            <span style={{ color: "gray", fontWeight: "bold" }}>By Checking this checkbox, you agree to Memedify Terms of Service.</span> 
                        </div>
                        <div className="newpost-properties">
                            <span className="newpost-error-label">{unbanError}</span>
                        </div> <br />
                        {
                            (!unbanLoading) ?
                                <button className="btn" style={{ padding: "15px 30px 15px 30px" }} onClick={unban}>Un-Ban</button>
                                :
                                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}><LoadingSmall size="50px" /><button className="btn" style={{ padding: "15px 30px 15px 30px", opacity: 0.5 }} onClick={unban}>Un-Ban</button></div>
                        }
                    </div>

            } <br /> <br />
             
            <button className="btn banpage-btn" style={{ padding: "10px 20px 10px 20px" }} onClick={logout}>Log Out</button> <br /> <br />
            
        </div>);
    }

  return (
    
    <>
        <nav className="navigationbar-container">
            <div className="navigationbar">
                <br />
                <div className="nav-menu-container">
                    <div className="nav-menu-left">
                        <div className="nav-logo-container">
                            <img src={logo} style={{ width: "50px" }}></img> 
                        </div>
                    </div>
                    <div className="nav-menu-right">
                        {
                            (localStorage.getItem("user") != "" && localStorage.getItem("user") != null) ? 
                            <>
                                {/* <div className="nav-property-container" style={(coins == undefined) ? { display: "none" } : {}}>
                                    <span >
                                        <img src={coinsImage} style={{width: "20px"}}></img>
                                        <label style={{fontSize: "20px", marginLeft: "5px"}}><strong>{coins}</strong></label>
                                    </span>
                                </div> */}
                                <div className="nav-property-container nav-property-container-interactable">
                                    <Link to="/notifications"><img src={notifIcon} style={{ width: "25px", display: "flex", justifyContent: "center" }}></img></Link>   
                                </div>
                                <div className="nav-property-container nav-property-container-interactable">
                                    <Link to="/dashboard"><img src={dashboardIcon} style={{ width: "25px", display: "flex", justifyContent: "center" }}></img></Link>   
                                </div>
                            </>
                            :
                            <></>
                        }
                        <div className="nav-property-container nav-property-container-interactable">
                            <Link to="/account"><img src={accountIcon} style={{ width: "25px", display: "flex", justifyContent: "center" }}></img></Link>   
                        </div>
                    </div>
                </div> <br></br>
                <div className="nav-details-container">
                    <div className="nav-pages-container" style={{ flexWrap: "wrap" }}>
                        
                        <button className="page-btn" onClick={() => redirect("/")}>
                            <img src={clickedHomeIcon} style={{ width: "25px" }} />
                            <span>Home</span>
                        </button>
                        
                        <button className="page-btn" onClick={() => redirect("categorytable")}>
                            <img src={clickedCategorytableIcon} style={{ width: "25px" }} />
                            <span>Category Table</span>
                        </button>
                        <button className="page-btn" onClick={() => redirect("follows")}>
                            <img src={clickedFollow} style={{ width: "25px" }} />
                            <span>Follows</span>
                        </button>
                        <button className="page-btn" onClick={() => redirect("createpost")}>
                            <img src={ClickedAddPostImg} style={{ width: "25px" }} />
                            <span>Add Meme</span>
                        </button>
                    </div>
                    {/* <select className="page-btn">
                        <option className="page-btn">
                            <Link to="/">
                                <span>Home</span>
                            </Link>
                        </option>
                        <option className="page-btn">
                            <Link to="/categorytable">
                                <span>Categorytable</span>
                            </Link>
                        </option>
                        <option className="page-btn">
                            <Link to="/follows">
                                <span>Follows</span>
                            </Link>
                        </option>
                        <option className="page-btn">
                            <Link to="/createpost">
                                <span>Add Post</span>
                            </Link>
                        </option>
                    </select> */}
                    <span className="nav-searchbar-container">
                        <div className="field nav-searchbar">
                            <input type="search" value={searchInput} onInput={(e) => setSearchInput(filterText(e.target.value, ["'", "`", "%", '"']))} className="input-field" placeholder="Search Memes..."></input>
                        </div>
                        <button className="nav-search-btn" onClick={() => redirect(`/search/${searchInput}`)}>
                                <img src={searchIcon} style={{ width: "20px" }}></img>
                        </button>
                    </span>
                            
                </div>
            </div>
        </nav>

    
        <Outlet />

        {/* <div className="status-container">
            <div className="status">
                <button className="status-home-btn">
                    <Link to="/"><img src={setImageSource("home")} style={{ width: "30px" }}></img></Link>
                    
                </button>

                <button className="status-categorytable-btn">
                    <Link to="/categorytable"><img src={setImageSource("categorytable")} style={{ width: "30px" }}></img></Link>
                </button>

                <button className="status-follows-btn">
                    <Link to="/follows"><img src={setImageSource("follows")} style={{ width: "30px" }}></img></Link>
                </button>
            </div>
        </div> */}

        <div className="bottom">
            <div className="bottom-content">
                <div className="bottom-copyright">
                    <label>copyright © 2024</label>
                    <img src={ordiousIcon} style={{ height: "20px", cursor: "pointer", marginLeft: "5px", marginRight: "5px" }} onClick={() => window.open("https://ordious.com", "_blank")} />
                    <label>by <strong>PopularSun</strong></label>.
                </div>
                {/* <div className="bottom-contact">
                    <strong>Contact Us!</strong> <br /> <br />
                    <strong>Memedify's official Email:</strong> memedify@ordious.com <br />
                    <strong>PopularSun's Email:</strong> popularsun@ordious.com
                </div> */}
            </div> 

        </div>
    </>
  );
}

export default Layout;
